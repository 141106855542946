import React from "react";
import "../scss/main.scss";
import uploadImage from "../images/uploadbg.svg";
import {Text} from '@adobe/react-spectrum';
import Dropzone from "react-dropzone";

export default class FileDrop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            image: null
        }

        this.label = React.createRef();

        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDropAccepted = this.handleDropAccepted.bind(this);
        this.handleDropRejected = this.handleDropRejected.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
    }

    handleDragEnter() {
        this.toggleOverlay(true);
    }

    handleDropAccepted(files) {
        this.toggleOverlay(false);

        const _this = this;
        const fileReader = new FileReader();
        fileReader.onload = function () {
            _this.setState({
                image: fileReader.result
            }, () => {
                if(_this.props.onFile) {
                    _this.props.onFile(files[0]);
                }
            });
        }
        fileReader.readAsDataURL(files[0]);
    }

    handleDropRejected(e) {
       this.toggleOverlay(false);

       const _this = this;
       this.setState({
           image: null,
       }, () => {
           if(_this.props.onError) {
               let message = "";
               switch(e[0].errors[0].code) {
                   case "file-invalid-type":
                       message = "Error: Invalid file type";
                       break;
                   case "file-too-large":
                       message = "Error: Image is larger than 5MB";
                       break;
                   default:
                       message = "Error: Invalid file"
                       break;
               }
               _this.props.onError(message);
           }
       })
    }

    handleDragLeave() {
        this.toggleOverlay(false);
    }

    toggleOverlay(isVisible) {
        if(this.label.current) {
            this.label.current.classList.toggle('FileDrop__dragover', isVisible);
        }
    }

    render() {
        let image = this.state.image ? `url('${this.state.image}')` : 'none';
        return (
            <Dropzone accept={["image/jpeg","image/png"]}
                      maxSize={10000000}
                      noClick={this.props.noClick}
                      disabled={this.props.isUploading || this.props.disabled}
                      onDragEnter={this.handleDragEnter}
                      onDragLeave={this.handleDragLeave}
                      onDropAccepted={this.handleDropAccepted}
                      onDropRejected={this.handleDropRejected}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="FileDrop"
                         style={{
                             backgroundImage: `url('${uploadImage}')`
                         }}>
                        <input {...getInputProps()} />
                        <div className="label" ref={this.label}>
                            <div className="prompt">
                                <Text>Drag a file or click to upload</Text>
                            </div>
                        </div>
                        <div className="inner" style={{
                            backgroundImage: image
                        }}/>
                        <div className="FileDrop__loading" style={{display: this.props.isUploading ? 'block' : 'none'}}/>
                    </div>
                )}
            </Dropzone>
        )
    }
}