import React from "react";
import '../scss/main.scss';
import {defaultTheme, Heading, Text, ProgressCircle, Link, Button, Provider} from '@adobe/react-spectrum';
import SubmissionForm from "./SubmissionForm";
import FileDrop from "./FileDrop";
import errorIcon from '../images/error_icon.svg';
import TileRepository from "../repositories/TileRepository";
import {navigate} from "@reach/router";
import ContentRepository from "../repositories/ContentRepository";
import DeviceRepository from "../repositories/DeviceRepository";
import AnalyticsRepository from "../repositories/AnalyticsRepository";
import {DateTime} from "luxon";

export default class Submit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            content: null,
            file: null,
            showError: false,
            isLarge: DeviceRepository.isLarge(),
            statusText: '',
            isUploading: false,
            isSuccess: false,
            tileId: ''
        }

        this.handleError = this.handleError.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
    }

    componentDidMount() {
        const _this = this;
        window.addEventListener('resize', () => {
            _this.setState({
                isLarge: DeviceRepository.isLarge()
            });
        });

        ContentRepository.fetchSiteContent()
            .then(content => {
                _this.setState({
                    content: content,
                    showError: ContentRepository.isDisconnected(),
                    statusText: ContentRepository.isDisconnected() ? 'Something went wrong. Please try again.' : '',
                }, () => {

                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.content !== prevState.content) {
            DeviceRepository.postHeightToParent();
            AnalyticsRepository.trackPageView('SubmitYourArt');
        }
        if(this.state.isSuccess && !prevState.isSuccess) {
            AnalyticsRepository.trackPageView('ThankYou');
        }
        if(this.props.isDisabled !== prevProps.isDisabled) {
            this.setState({
                isDisabled: this.props.isDisabled
            });
        }
    }

    getInstructionText() {
        if(!this.state.isLarge) {
            return (
                <Provider theme={defaultTheme} colorScheme="dark">
                    <Heading level={4}>Step 1: Upload Art</Heading>
                </Provider>
            )
        } else {
            return (
                <Provider theme={defaultTheme} colorScheme="dark">
                    <Heading level={1}>{this.state.content ? this.state.content.submitTitle : ""}</Heading>
                    <Text>{this.state.content ? this.state.content.submitBody : ""}</Text>
                </Provider>
            )
        }
    }

    getFormText() {
        if(this.state.isLarge) {
            return (
                <Heading level={4}>Your details</Heading>
            )
        } else {
            return (
                <Heading level={4}>Step 2: Add details</Heading>
            )
        }
    }

    handleError(error) {
        this.setState({
            file: null,
            showError: true,
            statusText: error,
            isUploading: false
        });
    }

    handleFile(file) {
        this.setState({
            file: file,
            showError: false,
            statusText: '',
        });
    }

    handleSubmit(data) {
        AnalyticsRepository.trackSubmitClicked();

        const _this = this;
        this.setState({
            isUploading: true,
            statusText: 'Uploading...'
        }, () => {
            TileRepository.submitImage(
                data.firstName,
                data.lastName,
                data.country,
                _this.state.file,
                _this.handleUploadProgress
            )
                .then(r => {
                    let tileId = r;
                    _this.setState({
                        isUploading: false,
                        statusText: '',
                        tileId: tileId,
                        isSuccess: true,
                    });
                })
                .catch(e => {
                    _this.handleError("There was a problem uploading the image. Check the submission link and try again.");
                });
        });
    }

    handleUploadProgress(e) {
        let progress = (e.loaded / e.total).toFixed(1);
        this.setState({
            statusText: progress < 1.0 ? `Uploading ${progress * 100}%` : `Saving submission...`
        })
    }

    isExpired() {
        if(!this.state.content || !this.state.content.json.expirationDate) {
            return true;
        }
        const expiration = new DateTime.fromISO(this.state.content.json.expirationDate.date, {zone: this.state.content.json.expirationDate.timezone});
        const now = DateTime.now().setZone(this.state.content.json.expirationDate.timezone);
        return now >= expiration;
    }

    render() {
        if(this.isExpired()) {
            /**
             * This layout renders the expired message and redirects to the home page.
             */
            return (
                <Provider theme={defaultTheme} colorScheme="light">
                    <div className="Submit">
                        <div className="Submit__gradient"/>
                        <div className="Submit__header">
                            <Provider theme={defaultTheme} colorScheme="dark">
                                <Heading level={4}>{this.state.content ? this.state.content.appTitle : ""}</Heading>
                                <Text>{` / ${this.state.content ? this.state.content.confirmationSubtitle : ""}`}</Text>
                            </Provider>
                        </div>
                        <div className="Submit__success">
                            <Provider theme={defaultTheme} colorScheme="dark">
                                <Heading level={1}>{this.state.content ? this.state.content.expirationTitle : ""}</Heading>

                                <div>
                                    <Text>{this.state.content ? this.state.content.expirationBody : ""}</Text>
                                    <br/><br/>
                                </div>
                                <div>
                                    <Button
                                    variant="cta"
                                    onPress={() => {navigate('/mural')}}>Browse the mural</Button>
                                </div>
                            </Provider>
                        </div>
                    </div>
                </Provider>
            )
        } else if(!this.state.isSuccess) {
            /**
             * This layout renders the basic upload form.
             */
            return (
                <Provider theme={defaultTheme} colorScheme="light">
                    <div className="Submit">
                        <div className="Submit__gradient"/>
                        <div className="Submit__header">
                            <Provider theme={defaultTheme} colorScheme="dark">
                                <Heading level={4}>{this.state.content ? this.state.content.appTitle : ""}</Heading>
                                <Text>{` / ${this.state.content ? this.state.content.submitSubtitle : ""}`}</Text>
                            </Provider>
                        </div>
                        <div className="Submit__instructions">
                            {this.getInstructionText()}
                        </div>
                        <div className="Submit__upload-container">
                            <FileDrop onError={this.handleError} onFile={this.handleFile} isUploading={this.state.isUploading} disabled={ContentRepository.isDisconnected()} noClick={ContentRepository.isDisconnected()}/>
                            <div className="Submit__status-container">
                                <img src={errorIcon} style={{display: this.state.showError ? 'inline' : 'none'}}/>
                                <ProgressCircle aria-label="Uploading…" isIndeterminate isHidden={!this.state.isUploading} marginEnd="8px"/>
                                <Text>{this.state.statusText}</Text>
                            </div>
                        </div>
                        <div className="Submit__form">
                            <SubmissionForm
                                isLarge={this.state.isLarge}
                                isEnabled={!!this.state.file && !this.state.isUploading}
                                onSubmit={this.handleSubmit}/>
                        </div>
                        <div className="Submit__success" style={{display: 'none'}}>
                            <Provider theme={defaultTheme} colorScheme="dark">
                            </Provider>
                        </div>
                    </div>
                </Provider>
            )
        } else {
            /**
             * This layout renders after submission is successful.
             */
            return (
                <Provider theme={defaultTheme} colorScheme="light">
                    <div className="Submit">
                        <div className="Submit__gradient"/>
                        <div className="Submit__header">
                            <Provider theme={defaultTheme} colorScheme="dark">
                                <Heading level={4}>{this.state.content ? this.state.content.appTitle : ""}</Heading>
                                <Text>{` / ${this.state.content ? this.state.content.confirmationSubtitle : ""}`}</Text>
                            </Provider>
                        </div>
                        <div className="Submit__success">
                            <Provider theme={defaultTheme} colorScheme="dark">
                                <Heading level={1}>{this.state.content ? this.state.content.confirmationTitle : ""}</Heading>

                                <div>
                                    <Text>{this.state.content ? this.state.content.confirmationBody : ""}</Text>
                                    <br/><br/>
                                </div>
                                <div className="Submit__success__bookmark-box">
                                    <div className="Submit__success__bookmark-prompt">
                                        <Text>Bookmark this link!</Text>
                                    </div>
                                    <br/>
                                    <Heading left={2}>
                                        <Link>
                                            <a href={`${process.env.GATSBY_MAX_URL}?tile=${this.state.tileId}`} target="_top">{`${process.env.GATSBY_MAX_URL}?tile=${this.state.tileId}`}</a>
                                        </Link>
                                    </Heading>
                                </div>
                                <br/><br/>
                            </Provider>
                        </div>
                    </div>
                </Provider>
            )
        }

    }
}