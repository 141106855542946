import {Button, defaultTheme, Flex, Form, Heading, Item, TextField, Picker, Provider, Text} from "@adobe/react-spectrum";
import React from "react";
import countries from "../countries.json";
import "../scss/main.scss";
import DeviceRepository from "../repositories/DeviceRepository";

export default class SubmissionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state  = {
            firstName: '',
            lastName: '',
            country: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getFormText() {
        if(this.props.isLarge) {
            return (
                <Heading level={4}>Your details</Heading>
            )
        } else {
            return (
                <Heading level={4}>Step 2: Add details</Heading>
            )
        }
    }

    handleSubmit() {
        if(this.props.onSubmit) {
            this.props.onSubmit({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                country: this.state.country
            });
        }
    }

    getCountryList(isIE = false) {
        if(isIE) {
            let modCountries = countries.slice();
            modCountries.unshift({name: '', code: 'empty'});
            return modCountries.map(country => {
                return <option value={country.name} key={country.name}>{country.name}</option>
            });
        } else {
            return countries.map(country => {
                return <Item textValue={country.name} key={country.name}><Text UNSAFE_className="Submit__form__listitem">{country.name}</Text></Item>
            });
        }
    }

    render() {
        if(DeviceRepository.isIE()) {
            return (
                <Provider theme={defaultTheme} colorScheme="light">
                    <Flex direction="column">
                        {this.getFormText()}
                        <Form maxWidth="size-3600" isRequired necessityIndicator="label">
                            <label htmlFor="first_name">First name</label>
                            <input name="first_name" type="text" className="fallback__input"
                                        placeholder="First name"
                                       onChange={v => this.setState({firstName: v})}/>
                            <label htmlFor="last_name">Last name</label>
                            <input name="last_name" type="text" className="fallback__input"
                                       placeholder="Last name"
                                       onChange={v => this.setState({lastName: v})}/>
                            <label htmlFor="country_select">Country (optional)</label>
                            <select name="country_select"
                                onChange={(e) => {this.setState({country: e.target.value})}}
                                placeholder="Country"
                                className="fallback__select">
                                {this.getCountryList(true)}
                            </select>
                            <div className="Submit__form__button">
                                <button
                                    className="fallback__button-cta"
                                    style={{maxWidth:'74px'}}
                                    disabled={!this.props.isEnabled}
                                    onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </Form>
                    </Flex>
                </Provider>
            );
        } else {
            return (
                <Provider theme={defaultTheme} colorScheme="light">
                    <Flex direction="column">
                        {this.getFormText()}
                        <Form maxWidth="size-3600" isRequired necessityIndicator="label">
                            <TextField label="First name"
                                       placeholder="First name"
                                       isRequired={false}
                                       onChange={v => this.setState({firstName: v})}/>
                            <TextField label="Last name"
                                       placeholder="Last name"
                                       isRequired={false}
                                       onChange={v => this.setState({lastName: v})}/>
                            <Provider theme={defaultTheme} colorScheme="light">
                                <Picker label="Country"
                                        isRequired={false}
                                        placeholder="Country"
                                        itemKey="name"
                                        shouldFlip={true}
                                        onSelectionChange={v => this.setState({country: v})}>
                                    {this.getCountryList()}
                                </Picker>
                            </Provider>
                            <div className="Submit__form__button">
                                <Button
                                    variant="cta"
                                    maxWidth="74px"
                                    isDisabled={!this.props.isEnabled}
                                    onPress={this.handleSubmit}>Submit</Button>
                            </div>
                        </Form>
                    </Flex>
                </Provider>
            );
        }
    }
}