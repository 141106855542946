import React from "react";
import "@ojp/ojp-elements/dist/ojp-elements/ojp-elements.css";
import '../scss/main.scss';
import withLocation from "../components/withLocation";
import App from "../components/App";
import {Helmet} from "react-helmet";
import Mural from "../components/Mural";
import AnalyticsRepository from "../repositories/AnalyticsRepository";
import Submit from "../components/Submit";
import ContentRepository from "../repositories/ContentRepository";

class IndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            content: null,
            isDisabled: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <script type="text/javascript">
                        {AnalyticsRepository.getAnalyticsBootstrap()}
                    </script>
                    <script src="https://www.adobe.com/marketingtech/main.min.js"></script>
                </Helmet>
                <Submit />
            </React.Fragment>
        );
    }

}

export default withLocation(IndexPage);